import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#00A159',
        secondary: '#E57373',
        accent: '#106EBE'
      },
      dark: {
        primary: '#00A159',
        secondary: '#E57373',
        accent: '#106EBE'
      }
    }
  },
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  }
})

import VitalsSource from '@/application/model/VitalsSource'
import Patient from '@/application/model/Patient'
import Hospital from '@/application/model/Hospital'
import PatientVitalsSource from '@/application/model/PatientVitalsSource'
import { VitalsThreshold } from '@/application/model/Thresholds'
import PatientDataProvider from '@/application/model/PatientDataProvider'
import fetcher from '@/application/backend/Fetcher'
import { Endpoints } from '@/application/backend/Endpoints'
import HospitalPatient from '@/application/model/HospitalPatient'

export const OrganizazionalApi = {
  // Hospitals
  getHospitals: (): Promise<Array<Hospital>> =>
    fetcher.get<Array<Hospital>>(Endpoints.hospitals()),
  postHospital: (hospital: Hospital): Promise<Hospital> =>
    fetcher.post<Hospital>(Endpoints.hospitals(), hospital),
  putHospital: (hospital: Hospital): Promise<Hospital> =>
    fetcher.put<Hospital>(Endpoints.hospital(hospital.id!), hospital),

  // Patient data providers
  getPatientDataProviders: (): Promise<Array<PatientDataProvider>> =>
    fetcher.get<Array<PatientDataProvider>>(Endpoints.patientDataProviders()),
  postPatientDataProvider: (patientDataProvider: PatientDataProvider): Promise<PatientDataProvider> =>
    fetcher.post<PatientDataProvider>(Endpoints.patientDataProviders(), patientDataProvider),
  putPatientDataProvider: (patientDataProvider: PatientDataProvider): Promise<PatientDataProvider> =>
    fetcher.put<PatientDataProvider>(Endpoints.patientDataProvider(patientDataProvider.id!), patientDataProvider),

  // Patients
  getPatients: (patientProviderID: string): Promise<Array<PatientVitalsSource>> =>
    fetcher.get<Array<PatientVitalsSource>>(Endpoints.patients(patientProviderID)),
  getPatient: (patientDataProviderId: string, patientDataProviderPatientId: string): Promise<PatientVitalsSource> =>
    fetcher.get<PatientVitalsSource>(Endpoints.patient(patientDataProviderId, patientDataProviderPatientId)),
  searchPatients: (patientDataProviderId: string, patient: Patient): Promise<Array<PatientVitalsSource>> =>
    fetcher.post<Array<PatientVitalsSource>>(Endpoints.patients(patientDataProviderId), patient),
  getAdmittedPatients: (hospitalId: string): Promise<Array<HospitalPatient>> =>
    fetcher.get<Array<HospitalPatient>>(Endpoints.admittedPatients(hospitalId)),
  postPatient: (dataProviderId: string, dataProviderPatientId: string, patient: Patient): Promise<Patient> =>
    fetcher.post<Patient>(Endpoints.patientDataProviderPatient(dataProviderId, dataProviderPatientId), patient),
  admitPatient: (hospitalId: string, patientId: string): Promise<HospitalPatient> =>
    fetcher.post(Endpoints.admitPatient(hospitalId, patientId), {}),
  dischargePatient: (hospitalId: string, patientId: string): Promise<null> =>
    fetcher.post(Endpoints.dischargePatient(hospitalId, patientId), {}),

  // Vital sources
  postVitalsSource: (vitalsSource: VitalsSource): Promise<VitalsSource> =>
    fetcher.post<VitalsSource>(Endpoints.vitalsSources(), vitalsSource),
  putVitalsSource: (vitalsSource: VitalsSource): Promise<VitalsSource> =>
    fetcher.put<VitalsSource>(Endpoints.vitalsSource(vitalsSource.id!), vitalsSource),
  getActiveVitalsSources: (patientId: string): Promise<Array<PatientVitalsSource>> =>
    fetcher.get<Array<PatientVitalsSource>>(Endpoints.activePatientVitalsSources(patientId)),
  getHospitalVitalsSources: (hospitalId: string): Promise<Array<VitalsSource>> =>
    fetcher.get<Array<VitalsSource>>(Endpoints.hospitalVitalsSources(hospitalId)),
  getPatientVitalsSources: (patientId: string): Promise<Array<PatientVitalsSource>> =>
    fetcher.get<Array<PatientVitalsSource>>(Endpoints.patientVitalsSources(patientId)),
  activateVitalsSource: (patientId: string, vitalsSourceId: string): Promise<PatientVitalsSource> =>
    fetcher.post<PatientVitalsSource>(Endpoints.activateVitalsSource(patientId, vitalsSourceId)),
  deactivateVitalsSource: (patientId: string, vitalsSourceId: string): Promise<null> =>
    fetcher.post<null>(Endpoints.deactivateVitalsSource(patientId, vitalsSourceId)),

  // Thresholds
  getHospitalVitalsThresholds: (hospitalId: string): Promise<Array<VitalsThreshold>> =>
    fetcher.get<Array<VitalsThreshold>>(Endpoints.hospitalVitalsThresholds(hospitalId)),
  putHospitalVitalsThresholds: (hospitalId: string, newVitalsThresholds: Array<VitalsThreshold>): Promise<Array<VitalsThreshold>> =>
    fetcher.put<Array<VitalsThreshold>>(Endpoints.hospitalVitalsThresholds(hospitalId), newVitalsThresholds),
  getPatientVitalsThresholds: (patientId: string): Promise<Array<VitalsThreshold>> =>
    fetcher.get<Array<VitalsThreshold>>(Endpoints.patientVitalsThresholds(patientId)),
  putPatientVitalsThresholds: (patientId: string, newVitalsThresholds: Array<VitalsThreshold>): Promise<Array<VitalsThreshold>> =>
    fetcher.put<Array<VitalsThreshold>>(Endpoints.patientVitalsThresholds(patientId), newVitalsThresholds)
}

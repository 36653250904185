import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

export const ROUTE_LOGIN = '/login'
export const ROUTE_HOSPITAL = '/hospital'
export const ROUTE_VITALS_SOURCES = '/vitalsSources'
export const ROUTE_PATIENTS = '/patients'
export const ROUTE_USER_SETTINGS = '/settings'
export const ROUTE_ADMIN = '/admin'
export const routes: Array<RouteConfig> = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: ROUTE_LOGIN,
    name: 'Login',
    component: () => import('../../components/login/LoginView.vue')
  },
  {
    path: ROUTE_HOSPITAL,
    name: 'Hospital',
    component: () => import('../../components/hospital/HospitalView.vue')
  },
  {
    path: ROUTE_PATIENTS,
    name: 'Patients',
    component: () => import('../../components/hospital/patients/AdmittedPatientsView.vue')
  },
  {
    path: ROUTE_VITALS_SOURCES,
    name: 'Vital Sources',
    component: () => import('../../components/hospital/vitalsources/VitalsSourcesView.vue')
  },
  {
    path: ROUTE_USER_SETTINGS,
    name: 'Settings',
    component: () => import('../../components/settings/SettingsView.vue')
  },
  {
    path: ROUTE_ADMIN,
    name: 'Admin',
    component: () => import('../../components/admin/AdminPanelView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

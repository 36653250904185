import { ActionContext } from 'vuex'
import { RootState } from '@/application/store/RootState'
import { VitalsThreshold } from '@/application/model/Thresholds'
import { OrganizazionalApi } from '@/application/backend/OrganizationalApi'
import HospitalPatient from '@/application/model/HospitalPatient'
import { BaseModule } from '@/application/store/BaseModule'
import { buildDeepQualifiedStoreMethodPath, NAMESPACE_SELECTED_PATIENT } from '@/application/store/RootStore'
import { GETTER_SELECTED_PATIENT } from '@/application/store/modules/selected/patient/SelectedPatientModule'

export const GETTER_ALL_VITALS_THRESHOLDS = 'getAll'
export const MUTATION_ALL_VITALS_THRESHOLDS = 'mutateAll'
export const ACTION_FETCH_ALL_VITALS_THRESHOLDS = 'fetchAll'

class Model {
  all = new Array<VitalsThreshold>()
}

class SelectedPatientVitalsThresholdsModule extends BaseModule<Model> {
  getters = {
    [GETTER_ALL_VITALS_THRESHOLDS]: (state: Model) => {
      console.log('getting the selected patient thresholds ' + JSON.stringify(state.all))
      return state.all
    }
  }

  mutations = {
    [MUTATION_ALL_VITALS_THRESHOLDS]: (state: Model, newVitalsThresholds: Array<VitalsThreshold>) => {
      console.log('mutating selected patient vital thresholds to ' + JSON.stringify(newVitalsThresholds))
      state.all = newVitalsThresholds
    }
  }

  actions = {
    [ACTION_FETCH_ALL_VITALS_THRESHOLDS]: async function (context: ActionContext<Model, RootState>): Promise<Array<VitalsThreshold>> {
      const selectedPatient: HospitalPatient | null = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT), GETTER_SELECTED_PATIENT)]
      if (!selectedPatient) {
        context.commit(MUTATION_ALL_VITALS_THRESHOLDS, [])
        return new Promise(() => [])
      }
      return OrganizazionalApi.getPatientVitalsThresholds(selectedPatient.patient!.id!)
        .then(result => {
          context.commit(MUTATION_ALL_VITALS_THRESHOLDS, result)
          return result
        })
    }
  }
}

export default new SelectedPatientVitalsThresholdsModule(new Model())

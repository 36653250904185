import { ActionContext } from 'vuex'
import { RootState } from '@/application/store/RootState'
import { VitalSignsApi } from '@/application/backend/VitalSignsApi'
import { VitalSignType } from '@/application/model/VitalSignType'
import { PulseRate } from '@/application/model/PulseRate'
import { OxygenSaturation } from '@/application/model/OxygenSaturation'
import { BodyTemperature } from '@/application/model/BodyTemperature'
import { BaseModule } from '@/application/store/BaseModule'
import HospitalPatient from '@/application/model/HospitalPatient'
import { buildDeepQualifiedStoreMethodPath, NAMESPACE_SELECTED_PATIENT } from '@/application/store/RootStore'
import { GETTER_SELECTED_PATIENT } from '@/application/store/modules/selected/patient/SelectedPatientModule'
import VitalSignRaw from '@/application/model/VitalSignRaw'
import VitalSign from '@/application/model/VitalSign'

export const GETTER_PULSE_RATES = 'getPulseRates'
export const GETTER_BODY_TEMPERATURES = 'getBodyTemperatures'
export const GETTER_OXYGEN_SATURATIONS = 'getOxygenSaturations'
export const MUTATION_ADD_PULSE_RATE = 'addPulseRate'
export const MUTATION_ADD_OXYGEN_SATURATION = 'addOxygenSaturation'
export const MUTATION_ADD_BODY_TEMPERATURE = 'addBodyTemperature'
export const ACTION_RESET = 'reset'
export const ACTION_FETCH_VITAL_SIGNS_BY_TYPE = 'fetchByType'
export const POLL_RATE = 3000
const PING = 'ping'
const MAX_ENTRIES = 20
const MOCK = false

class Model {
  pulseRates = new Array<PulseRate>()

  oxygenSaturations = new Array<OxygenSaturation>()

  bodyTemperatures = new Array<BodyTemperature>()
}

class SelectedPatientVitalSignsModule extends BaseModule<Model> {
  getters = {
    [GETTER_PULSE_RATES]: (state: Model) => state.pulseRates,
    [GETTER_BODY_TEMPERATURES]: (state: Model) => state.bodyTemperatures,
    [GETTER_OXYGEN_SATURATIONS]: (state: Model) => state.oxygenSaturations
  }

  mutations = {
    [MUTATION_ADD_PULSE_RATE]: (state: Model, pulseRate: PulseRate) => {
      state.pulseRates = this.addToVitals(state.pulseRates, pulseRate)
    },
    [MUTATION_ADD_OXYGEN_SATURATION]: (state: Model, oxygenSaturation: OxygenSaturation) => {
      state.oxygenSaturations = this.addToVitals(state.oxygenSaturations, oxygenSaturation)
    },
    [MUTATION_ADD_BODY_TEMPERATURE]: (state: Model, bodyTemperature: BodyTemperature) => {
      state.bodyTemperatures = this.addToVitals(state.bodyTemperatures, bodyTemperature)
    }
  }

  actions = {
    [ACTION_FETCH_VITAL_SIGNS_BY_TYPE]: async function (context: ActionContext<Model, RootState>, vitalSignType: VitalSignType): Promise<EventSource> {
      const selectedPatient: HospitalPatient = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT), GETTER_SELECTED_PATIENT)]
      const eventSource = VitalSignsApi.getPatientActiveVitals(selectedPatient.patient!.id!, vitalSignType, POLL_RATE, MOCK)
      eventSource.onmessage = (event) => {
        if (event.data === PING) {
          return eventSource
        }
        const vitalSignRaw: VitalSignRaw = JSON.parse(event.data)
        const vitalSign = VitalSign.fromRaw(vitalSignRaw)
        if (vitalSignType !== vitalSign.type) {
          throw new Error('Error while receiving vital signs, expected vital sign type ' + vitalSignType +
            ' but received ' + vitalSign.type)
        }
        if (vitalSign instanceof PulseRate) {
          context.commit(MUTATION_ADD_PULSE_RATE, vitalSign)
        } else if (vitalSign instanceof BodyTemperature) {
          context.commit(MUTATION_ADD_BODY_TEMPERATURE, vitalSign)
        } else if (vitalSign instanceof OxygenSaturation) {
          context.commit(MUTATION_ADD_OXYGEN_SATURATION, vitalSign)
        } else {
          throw new Error('Can not process vital sign (unknown class): ' + JSON.stringify(vitalSign))
        }
      }
      eventSource.onerror = (event) => {
        console.error('Error while fetching vital signs: ' + JSON.stringify(event))
      }
      return eventSource
    }
  }

  private addToVitals (vitalSigns: Array<VitalSign>, newVitalSign: VitalSign): Array<VitalSign> {
    if (vitalSigns.length > MAX_ENTRIES) {
      const result: Array<VitalSign> = []
      result.push(newVitalSign)
      return result
    } else {
      const result: Array<VitalSign> = vitalSigns.slice()
      result.push(newVitalSign)
      return result
    }
  }
}

export default new SelectedPatientVitalSignsModule(new Model())

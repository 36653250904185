import { VitalSignType } from '@/application/model/VitalSignType'
import VitalSign from '@/application/model/VitalSign'

export const PULSE_RATE_CHART_MIN = 0

export const PULSE_RATE_CHART_MAX = 220

export const PULSE_RATE_CHART_TICK = 22

export class PulseRate implements VitalSign {
  type = VitalSignType.PULSE_RATE

  value: number

  timestamp: moment.Moment

  constructor (value: number, timestamp: moment.Moment) {
    this.value = value
    this.timestamp = timestamp
  }
}

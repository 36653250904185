import { VitalSignType } from '@/application/model/VitalSignType'
import VitalSign from '@/application/model/VitalSign'

export const OXYGEN_SATURATION_CHART_MIN = 90

export const OXYGEN_SATURATION_CHART_MAX = 100

export const OXYGEN_SATURATION_CHART_TICK = 10

export class OxygenSaturation implements VitalSign {
  type = VitalSignType.OXYGEN_SATURATION

  value: number

  timestamp: moment.Moment

  constructor (value: number, timestamp: moment.Moment) {
    this.value = value
    this.timestamp = timestamp
  }
}

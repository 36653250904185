import { ActionContext } from 'vuex'
import { RootState } from '@/application/store/RootState'
import { OrganizazionalApi } from '@/application/backend/OrganizationalApi'
import PatientVitalsSource from '@/application/model/PatientVitalsSource'
import HospitalPatient from '@/application/model/HospitalPatient'
import { BaseModule } from '@/application/store/BaseModule'
import { buildDeepQualifiedStoreMethodPath, NAMESPACE_SELECTED_PATIENT } from '@/application/store/RootStore'
import { GETTER_SELECTED_PATIENT } from '@/application/store/modules/selected/patient/SelectedPatientModule'

export const GETTER_ALL_VITALS_SOURCES = 'getAll'
export const MUTATION_ALL_VITALS_SOURCES = 'mutateAll'
export const ACTION_FETCH_ALL_VITALS_SOURCES = 'fetchAll'
export const ACTION_ACTIVATE_VITALS_SOURCE = 'activate'
export const ACTION_DEACTIVATE_VITALS_SOURCE = 'deactivate'

class Model {
  all: Array<PatientVitalsSource> = new Array<PatientVitalsSource>()
}

class SelectedPatientVitalsSourcesModule extends BaseModule<Model> {
  getters = {
    [GETTER_ALL_VITALS_SOURCES]: (state: Model) => {
      console.log('getting all vital sources for selected patient')
      return state.all
    }
  }

  mutations = {
    [MUTATION_ALL_VITALS_SOURCES]: (state: Model, newPatientVitalsSources: Array<PatientVitalsSource>) => {
      console.log('mutating all patient vital sources')
      state.all = newPatientVitalsSources
    }
  }

  actions = {
    [ACTION_FETCH_ALL_VITALS_SOURCES]: async function (context: ActionContext<Model, RootState>): Promise<Array<PatientVitalsSource>> {
      const selectedPatient: HospitalPatient | null = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT), GETTER_SELECTED_PATIENT)]
      if (!selectedPatient) {
        context.commit(MUTATION_ALL_VITALS_SOURCES, [])
        return new Promise(() => [])
      }
      return OrganizazionalApi.getPatientVitalsSources(selectedPatient.patient!.id!)
        .then(result => {
          context.commit(MUTATION_ALL_VITALS_SOURCES, result)
          return result
        })
    },
    [ACTION_ACTIVATE_VITALS_SOURCE]: async function (context: ActionContext<Model, RootState>, vitalsSourceId: string) {
      const selectedPatient: HospitalPatient = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT), GETTER_SELECTED_PATIENT)]
      const result: Promise<PatientVitalsSource> = OrganizazionalApi.activateVitalsSource(selectedPatient.patient!.id!, vitalsSourceId)
      await result
      context.dispatch(ACTION_FETCH_ALL_VITALS_SOURCES)
      return result
    },
    [ACTION_DEACTIVATE_VITALS_SOURCE]: async function (context: ActionContext<Model, RootState>, vitalsSourceId: string) {
      const selectedPatient: HospitalPatient = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT), GETTER_SELECTED_PATIENT)]
      const result: Promise<null> = OrganizazionalApi.deactivateVitalsSource(selectedPatient.patient!.id!, vitalsSourceId)
      await result
      context.dispatch(ACTION_FETCH_ALL_VITALS_SOURCES)
      return result
    }
  }
}

export default new SelectedPatientVitalsSourcesModule(new Model())

import Hospital from '@/application/model/Hospital'
import { ActionContext } from 'vuex'
import { RootState } from '@/application/store/RootState'
import { BaseModule } from '@/application/store/BaseModule'
import SelectedHospitalVitalsSourcesModule, { ACTION_FETCH_ALL_VITALS_SOURCES } from '@/application/store/modules/selected/hospital/SelectedHospitalVitalsSourcesModule'
import SelectedHospitalVitalsThresholdsModule, { ACTION_FETCH_ALL_VITALS_THRESHOLDS } from '@/application/store/modules/selected/hospital/SelectedHospitalVitalsThresholdsModule'
import SelectedHospitalPatientsModule, { ACTION_FETCH_ADMITTED_PATIENTS } from '@/application/store/modules/selected/hospital/SelectedHospitalPatientsModule'
import { buildDeepQualifiedStoreMethodPath } from '@/application/store/RootStore'

export const NAMESPACE_PATIENTS = 'patients'
export const NAMESPACE_VITALS_SOURCES = 'vitalsSources'
export const NAMESPACE_VITALS_THRESHOLDS = 'vitalsThresholds'
export const STATE_SELECTED_HOSPITAL = 'hospital'
export const GETTER_SELECTED_HOSPITAL = 'getHospital'
export const MUTATION_SELECTED_HOSPITAL = 'mutateHospital'
export const ACTION_SET_SELECTED_HOSPITAL = 'setHospital'

class Model {
  [STATE_SELECTED_HOSPITAL]: Hospital | null = null
}

class SelectedHospitalModule extends BaseModule<Model> {
  modules = {
    [NAMESPACE_PATIENTS]: SelectedHospitalPatientsModule,
    [NAMESPACE_VITALS_SOURCES]: SelectedHospitalVitalsSourcesModule,
    [NAMESPACE_VITALS_THRESHOLDS]: SelectedHospitalVitalsThresholdsModule
  }

  getters = {
    [GETTER_SELECTED_HOSPITAL]: (state: Model) => {
      console.log('getting the selected hospital ' + JSON.stringify(state.hospital))
      return state.hospital
    }
  }

  mutations = {
    [MUTATION_SELECTED_HOSPITAL]: (state: Model, hospital: Hospital) => {
      console.log('mutating selected hospital to ' + JSON.stringify(hospital))
      state.hospital = hospital
    }
  }

  actions = {
    [ACTION_SET_SELECTED_HOSPITAL]: async function (context: ActionContext<Model, RootState>, newHospital: Hospital) {
      context.commit(MUTATION_SELECTED_HOSPITAL, newHospital)
      context.dispatch(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_PATIENTS), ACTION_FETCH_ADMITTED_PATIENTS))
      context.dispatch(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_VITALS_SOURCES), ACTION_FETCH_ALL_VITALS_SOURCES))
      context.dispatch(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_VITALS_THRESHOLDS), ACTION_FETCH_ALL_VITALS_THRESHOLDS))
    }
  }
}

export default new SelectedHospitalModule(new Model())

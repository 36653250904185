




































import { Component } from 'vue-property-decorator'
import BaseModel from '../../application/model/BaseModel'
import Hospital from '@/application/model/Hospital'
import { Action, Getter } from 'vuex-class'
import {
  ACTION_SET_SELECTED_HOSPITAL,
  GETTER_SELECTED_HOSPITAL
} from '@/application/store/modules/selected/hospital/SelectedHospitalModule'
import TooltipView from '@/components/common/TooltipView.vue'
import {
  buildQualifiedStoreMethodPath,
  NAMESPACE_HOSPITALS,
  NAMESPACE_SELECTED_HOSPITAL
} from '@/application/store/RootStore'
import { ACTION_FETCH_HOSPITALS, GETTER_HOSPITALS } from '@/application/store/modules/HospitalsModule'
import Patient from '@/application/model/Patient'

@Component({
  components: { Tooltip: TooltipView }
})
export default class SelectHospitalView extends BaseModel {
  @Action(buildQualifiedStoreMethodPath(NAMESPACE_HOSPITALS, ACTION_FETCH_HOSPITALS))
  fetchHospitals!: () => Promise<Array<Hospital>>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_HOSPITALS, GETTER_HOSPITALS))
  hospitals!: Array<Hospital>

  @Action(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_HOSPITAL, ACTION_SET_SELECTED_HOSPITAL))
  setSelectedHospital!: (newPatient: Hospital | null) => Promise<Array<Patient>>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_HOSPITAL, GETTER_SELECTED_HOSPITAL))
  getSelectedHospital!: Hospital | null

  isDialogOpen = false

  isHospitalChangeable = true

  constructor () {
    super('header')
  }

  get selectedHospital (): Hospital | null {
    return this.getSelectedHospital
  }

  set selectedHospital (newHospital: Hospital | null) {
    this.setSelectedHospital(newHospital)
  }

  async mounted (): Promise<void> {
    await this.fetchHospitals()
    this.isDialogOpen = this.hospitals && this.hospitals.length > 0 && !this.selectedHospital
    this.isHospitalChangeable = this.hospitals && this.hospitals.length > 1
  }

  openDialog (): void {
    this.selectedHospital = null
    this.isDialogOpen = true
  }

  onHospitalSelected (): void {
    this.isDialogOpen = false
  }
}


import { ActionContext } from 'vuex'
import VitalsSource from '@/application/model/VitalsSource'
import { RootState } from '@/application/store/RootState'
import Hospital from '@/application/model/Hospital'
import { OrganizazionalApi } from '@/application/backend/OrganizationalApi'
import { BaseModule } from '@/application/store/BaseModule'
import { buildDeepQualifiedStoreMethodPath, NAMESPACE_SELECTED_HOSPITAL } from '@/application/store/RootStore'
import { GETTER_SELECTED_HOSPITAL } from '@/application/store/modules/selected/hospital/SelectedHospitalModule'

export const GETTER_ALL_VITALS_SOURCES = 'getAll'
export const MUTATION_ALL_VITALS_SOURCES = 'setAll'
export const ACTION_FETCH_ALL_VITALS_SOURCES = 'fetchAll'
export const ACTION_POST_VITALS_SOURCE = 'post'
export const ACTION_PUT_VITALS_SOURCE = 'put'

class Model {
  all: Array<VitalsSource> = new Array<VitalsSource>()
}

class SelectedHospitalVitalsSourcesModule extends BaseModule<Model> {
  getters = {
    [GETTER_ALL_VITALS_SOURCES]: (state: Model) => {
      console.log('getting all vital sources for selected hospital')
      return state.all
    }
  }

  mutations = {
    [MUTATION_ALL_VITALS_SOURCES]: (state: Model, newVitalsSources: Array<VitalsSource>) => {
      console.log('mutating all vital sources for selected hospital')
      state.all = newVitalsSources
    }
  }

  actions = {
    [ACTION_FETCH_ALL_VITALS_SOURCES]: async function (context: ActionContext<Model, RootState>): Promise<Array<VitalsSource>> {
      const selectedHospital: Hospital | null = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL), GETTER_SELECTED_HOSPITAL)]
      if (!selectedHospital) {
        context.commit(MUTATION_ALL_VITALS_SOURCES, [])
        return new Promise(() => [])
      }
      return OrganizazionalApi.getHospitalVitalsSources(selectedHospital.id!)
        .then(result => {
          context.commit(MUTATION_ALL_VITALS_SOURCES, result)
          return result
        })
    },
    [ACTION_POST_VITALS_SOURCE]: async function (context: ActionContext<Model, RootState>, vitalsSource: VitalsSource) {
      const selectedHospital: Hospital = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL), GETTER_SELECTED_HOSPITAL)]
      vitalsSource.hospital = selectedHospital
      const result: Promise<VitalsSource> = OrganizazionalApi.postVitalsSource(vitalsSource)
      await result
      context.dispatch(ACTION_FETCH_ALL_VITALS_SOURCES)
      return result
    },
    [ACTION_PUT_VITALS_SOURCE]: async function (context: ActionContext<Model, RootState>, vitalsSource: VitalsSource) {
      const result: Promise<VitalsSource> = OrganizazionalApi.putVitalsSource(vitalsSource)
      await result
      context.dispatch(ACTION_FETCH_ALL_VITALS_SOURCES)
      return result
    }
  }
}

export default new SelectedHospitalVitalsSourcesModule(new Model())

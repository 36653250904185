import { VitalSignType } from '@/application/model/VitalSignType'

export enum BaseURL {
  ORGANIZATIONAL_SERVICE = 'https://chm-organizational-service.ext.birkle-it.net/api',
  // ORGANIZATIONAL_SERVICE = 'http://localhost:8081/api',
  VITALS_SERVICE = 'https://chm-vitals-service.ext.birkle-it.net/api',
  // VITALS_SERVICE = 'http://localhost:8082/api',
}

export const Endpoints = {
  hospitals: () => `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals`,

  hospital: (hospitalId: string) => `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals/${hospitalId}`,

  patientDataProviders: () => `${BaseURL.ORGANIZATIONAL_SERVICE}/patientDataProviders`,

  patientDataProvider: (patientProviderId: string) => `${BaseURL.ORGANIZATIONAL_SERVICE}/patientDataProviders/${patientProviderId}`,

  patientDataProviderPatient: (patientProviderId: string, patientDataProviderPatientId: string): string =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/patientDataProviders/${patientProviderId}/patients/${patientDataProviderPatientId}`,

  patients: (externalPatientId: string): string =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/patientDataProviders/${externalPatientId}/patients`,

  patient: (patientDataProviderId: string, patientDataProviderPatientId: string): string =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/patientDataProviders/${patientDataProviderId}/patients/${patientDataProviderPatientId}`,

  admittedPatients: (hospitalId: string): string =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals/${hospitalId}/patients`,

  admitPatient: (hospitalId: string, patientId: string): string =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals/${hospitalId}/patients/${patientId}/admit`,

  dischargePatient: (hospitalId: string, patientId: string): string =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals/${hospitalId}/patients/${patientId}/discharge`,

  vitalsSources: () =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/vitalsSources`,

  vitalsSource: (vitalsSourceId: string): string =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/vitalsSources/${vitalsSourceId}`,

  hospitalVitalsSources: (hospitalId: string) =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals/${hospitalId}/vitals/sources`,

  patientVitalsSources: (patientId: string) =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/patients/${patientId}/vitals/sources`,

  activateVitalsSource: (patientId: string, vitalsSourceId: string) =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/patients/${patientId}/vitals/sources/${vitalsSourceId}/activate`,

  deactivateVitalsSource: (patientId: string, vitalsSourceId: string) =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/patients/${patientId}/vitals/sources/${vitalsSourceId}/deactivate`,

  activePatientVitalsSources: (patientId: string) =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/patients/${patientId}/vitals/sources/active`,

  hospitalVitalsThresholds: (hospitalId: string) =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals/${hospitalId}/vitals/thresholds`,

  patientVitalsThresholds: (patientId: string) =>
    `${BaseURL.ORGANIZATIONAL_SERVICE}/patients/${patientId}/vitals/thresholds`,

  patientVitals: (patientId: string, vitalSignType: VitalSignType, mock: boolean) =>
    `${BaseURL.VITALS_SERVICE}/patients/${patientId}/vitals/${vitalSignType}?mock=${mock}`,

  patientActiveVitals: (patientId: string, vitalSignType: VitalSignType, pollRateMillis: number, mock: boolean) =>
    `${BaseURL.VITALS_SERVICE}/patients/${patientId}/vitals/${vitalSignType}/active?pollRateMillis=${pollRateMillis}&mock=${mock}`,

  vitalsSourceVitals: (vitalsSourceId: string, vitalSignType: VitalSignType, pollRateMillis: number, mock: boolean) =>
    `${BaseURL.VITALS_SERVICE}/vitalsSources/${vitalsSourceId}/vitals/${vitalSignType}?pollRateMillis=${pollRateMillis}&mock=${mock}`

}

import { ActionContext } from 'vuex'
import PatientDataProvider from '@/application/model/PatientDataProvider'
import { RootState } from '@/application/store/RootState'
import { OrganizazionalApi } from '@/application/backend/OrganizationalApi'
import { BaseModule } from '@/application/store/BaseModule'
import { ACTION_FETCH_HOSPITALS } from '@/application/store/modules/HospitalsModule'
import { buildQualifiedStoreMethodPath, NAMESPACE_HOSPITALS } from '@/application/store/RootStore'

export const STATE_PATIENT_DATA_PROVIDERS = 'all'
export const GETTER_PATIENT_DATA_PROVIDERS = 'getAll'
export const MUTATION_SET_PATIENT_DATA_PROVIDERS = 'setAll'
export const ACTION_FETCH_PATIENT_DATA_PROVIDERS = 'fetchAll'
export const ACTION_POST_PATIENT_DATA_PROVIDER = 'post'
export const ACTION_PUT_PATIENT_DATA_PROVIDER = 'put'

class Model {
  [STATE_PATIENT_DATA_PROVIDERS] = new Array<PatientDataProvider>()
}

class PatientDataProviderModule extends BaseModule<Model> {
  getters = {
    [GETTER_PATIENT_DATA_PROVIDERS]: (state: Model) => {
      console.log('getting patient data providers from state')
      return state.all
    }
  }

  mutations = {
    [MUTATION_SET_PATIENT_DATA_PROVIDERS]: (state: Model, newProviders: Array<PatientDataProvider>) => {
      console.log('mutating all patient data providers state')
      state.all = newProviders
    }
  }

  actions = {
    [ACTION_FETCH_PATIENT_DATA_PROVIDERS]: async function (context: ActionContext<Model, RootState>) {
      return OrganizazionalApi.getPatientDataProviders()
        .then(result => {
          context.commit(MUTATION_SET_PATIENT_DATA_PROVIDERS, result)
          return result
        })
    },
    [ACTION_POST_PATIENT_DATA_PROVIDER]: async function (context: ActionContext<Model, RootState>, patientDataProvider: PatientDataProvider) {
      return OrganizazionalApi.postPatientDataProvider(patientDataProvider)
        .then(result => {
          context.dispatch(ACTION_FETCH_PATIENT_DATA_PROVIDERS)
          context.dispatch(buildQualifiedStoreMethodPath(NAMESPACE_HOSPITALS, ACTION_FETCH_HOSPITALS), null, { root: true })
          return result
        })
    },
    [ACTION_PUT_PATIENT_DATA_PROVIDER]: async function (context: ActionContext<Model, RootState>, patientDataProvider: PatientDataProvider) {
      return OrganizazionalApi.putPatientDataProvider(patientDataProvider)
        .then(result => {
          context.dispatch(ACTION_FETCH_PATIENT_DATA_PROVIDERS)
          context.dispatch(buildQualifiedStoreMethodPath(NAMESPACE_HOSPITALS, ACTION_FETCH_HOSPITALS), null, { root: true })
          return result
        })
    }
  }
}

export default new PatientDataProviderModule(new Model())

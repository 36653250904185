import { Vue } from 'vue-property-decorator'
import { ROUTE_LOGIN } from '@/application/router/router'

export default class BaseModel extends Vue {
  protected tableFooterProps = {
    'items-per-page-text': this.getLangTextWithoutPrefix('common.table.items-per-page-text'),
    'items-per-page-all-text': this.getLangTextWithoutPrefix('common.table.items-per-page-all-text')
  }

  protected tableNoDataText = this.getLangTextWithoutPrefix('common.table.no-data-text')

  protected tableNoResultsText = this.getLangTextWithoutPrefix('common.table.no-results-text')

  private langPrefix: string

  constructor (langPrefix: string) {
    super()
    this.langPrefix = langPrefix
  }

  ruleIsNotEmpty = (input: string): boolean | string => {
    if (!input || input.length === 0) {
      return this.getLangTextWithoutPrefix('common.validation.notEmpty')
    }
    return true
  }

  ruleIsNumeric = (input: string): boolean | string => {
    if (!input || input.length === 0 || isNaN(Number(input))) {
      return this.getLangTextWithoutPrefix('common.validation.isNotNumeric')
    }
    return true
  }

  ruleIsPercentage = (input: string): boolean | string => {
    if (!input || isNaN(Number(input)) || Number(input) < 0 || Number(input) > 1) {
      return this.getLangTextWithoutPrefix('common.validation.isNotPercentage')
    }
    return true
  }

  ruleIsEmail = (input: string): boolean | string => {
    if (!input || !input.includes('@')) {
      return this.getLangTextWithoutPrefix('common.validation.notValidEmail')
    }
    return true
  }

  protected isLoginPage (): boolean {
    return !this.$route.matched.some(({ name }) => name === ROUTE_LOGIN)
  }

  protected getLangText (path: string, data = ''): string {
    return this.getLangTextWithoutPrefix(this.langPrefix + '.' + path, data)
  }

  protected getLangTextWithoutPrefix (path: string, data = ''): string {
    return this.$t(path, data).toString()
  }

  protected prettyDate (rawDate: any) {
    if (!rawDate) return ''
    const date = new Date(rawDate)
    return `${date.toLocaleTimeString()}/ ${date.toLocaleDateString()}`
  }
}

export interface Form {
  validate (): boolean

  reset (): void
}

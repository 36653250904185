import HospitalPatient from '@/application/model/HospitalPatient'
import { BaseModule } from '@/application/store/BaseModule'
import { ActionContext } from 'vuex'
import { RootState } from '@/application/store/RootState'
import SelectedPatientVitalsSourcesModule, { ACTION_FETCH_ALL_VITALS_SOURCES } from '@/application/store/modules/selected/patient/SelectedPatientVitalsSourcesModule'
import { buildQualifiedStoreMethodPath } from '@/application/store/RootStore'
import SelectedPatientVitalsThresholdsModule, { ACTION_FETCH_ALL_VITALS_THRESHOLDS } from '@/application/store/modules/selected/patient/SelectedPatientVitalsThresholdsModule'
import VitalsSources from '@/components/hospital/vitalsources/VitalsSources.vue'
import { VitalsThreshold } from '@/application/model/Thresholds'
import SelectedPatientVitalSignsModule
  from '@/application/store/modules/selected/patient/SelectedPatientVitalSignsModule'

export const STATE_SELECTED_PATIENT = 'hospitalPatient'
export const NAMESPACE_VITAL_SIGNS = 'vitalSigns'
export const NAMESPACE_VITALS_SOURCES = 'vitalsSources'
export const NAMESPACE_VITALS_THRESHOLDS = 'vitalsThresholds'
export const GETTER_SELECTED_PATIENT = 'getHospitalPatient'
export const MUTATION_SELECTED_PATIENT = 'mutateHospitalPatient'
export const ACTION_SET_SELECTED_PATIENT = 'setHospitalPatient'

class Model {
  [STATE_SELECTED_PATIENT]: HospitalPatient | null = null
}

class SelectedPatientModule extends BaseModule<Model> {
  modules = {
    [NAMESPACE_VITALS_SOURCES]: SelectedPatientVitalsSourcesModule,
    [NAMESPACE_VITALS_THRESHOLDS]: SelectedPatientVitalsThresholdsModule,
    [NAMESPACE_VITAL_SIGNS]: SelectedPatientVitalSignsModule
  }

  getters = {
    [GETTER_SELECTED_PATIENT]: (state: Model) => {
      console.log('getting selected patient (with vital sources) ' + JSON.stringify(state.hospitalPatient))
      return state.hospitalPatient
    }
  }

  mutations = {
    [MUTATION_SELECTED_PATIENT]: (state: Model, patientVitalsSources: HospitalPatient) => {
      console.log('mutating selected patient (with vital sources) to ' + JSON.stringify(patientVitalsSources))
      state.hospitalPatient = patientVitalsSources
    }
  }

  actions = {
    [ACTION_SET_SELECTED_PATIENT]: async function (context: ActionContext<Model, RootState>, newPatient: HospitalPatient) {
      context.commit(MUTATION_SELECTED_PATIENT, newPatient)
      const promiseVitalsSources: Promise<VitalsSources> = context.dispatch(buildQualifiedStoreMethodPath(NAMESPACE_VITALS_SOURCES, ACTION_FETCH_ALL_VITALS_SOURCES))
      const promiseVitalsThresholds: Promise<VitalsThreshold> = context.dispatch(buildQualifiedStoreMethodPath(NAMESPACE_VITALS_THRESHOLDS, ACTION_FETCH_ALL_VITALS_THRESHOLDS))
      return Promise.all([promiseVitalsSources, promiseVitalsThresholds])
        .then(value => newPatient)
    }
  }
}

export default new SelectedPatientModule(new Model())

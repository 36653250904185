import Vue from 'vue'
import Vuex, { ActionTree, ModuleTree } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { RootState } from '@/application/store/RootState'
import HospitalsModule from '@/application/store/modules/HospitalsModule'
import PatientDataProviderModule from '@/application/store/modules/PatientDataProviderModule'
import UserContextModule from '@/application/store/modules/UserContextModule'
import SelectedHospitalModule from '@/application/store/modules/selected/hospital/SelectedHospitalModule'
import SelectedPatientModule from '@/application/store/modules/selected/patient/SelectedPatientModule'

Vue.use(Vuex)

export const NAMESPACE_USER_CONTEXT = 'userContext'
export const NAMESPACE_PATIENT_DATA_PROVIDERS = 'patientDataProviders'
export const NAMESPACE_HOSPITALS = 'hospitals'
export const NAMESPACE_SELECTED_HOSPITAL = 'selectedHospital'
export const NAMESPACE_SELECTED_PATIENT = 'selectedPatient'

class RootStore extends Vuex.Store<RootState> {
  constructor () {
    const persistedState = createPersistedState({
      paths: [
        NAMESPACE_USER_CONTEXT,
        NAMESPACE_PATIENT_DATA_PROVIDERS,
        NAMESPACE_HOSPITALS,
        NAMESPACE_SELECTED_HOSPITAL,
        NAMESPACE_SELECTED_PATIENT
      ]
    })
    const modules = RootStore.getModules()
    const actions = RootStore.getActions()
    const options = {
      plugins: [persistedState],
      modules: modules,
      actions: actions
    }
    super(options)
  }

  public static getModules (): ModuleTree<RootState> {
    return {
      [NAMESPACE_USER_CONTEXT]: UserContextModule,
      [NAMESPACE_PATIENT_DATA_PROVIDERS]: PatientDataProviderModule,
      [NAMESPACE_HOSPITALS]: HospitalsModule,
      [NAMESPACE_SELECTED_HOSPITAL]: SelectedHospitalModule,
      [NAMESPACE_SELECTED_PATIENT]: SelectedPatientModule
    }
  }

  public static getActions (): ActionTree<RootState, RootState> {
    return {}
  }
}

export function buildNamespacePath (namespaces: Array<string>): string {
  return namespaces.join('/')
}

export function buildDeepQualifiedStoreMethodPath (namespaces: Array<string>, method: string): string {
  return buildNamespacePath(namespaces) + '/' + method
}

export function buildQualifiedStoreMethodPath (namespace: string, method: string): string {
  return namespace + '/' + method
}

export function buildQualifiedStoreStatePath (namespace: string, method: string): string {
  return namespace + '.' + method
}

export default new RootStore()

enum METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

class Fetcher {
  public get<T> (url: string, body?: unknown): Promise<T> {
    console.log('executing fetch with method %s on url %s', METHOD.GET, url)
    return fetch(url, this.createRequest(METHOD.GET, body))
      .then(response => {
        this.validateStatus(response)
        if (response.bodyUsed) {
          throw new Error('Can not parse body to json. Received empty body')
        }
        return this.toJson(response)
      })
  }

  public post<T> (url: string, body?: unknown): Promise<T> {
    console.log('executing fetch with method %s on url %s', METHOD.POST, url)
    return fetch(url, this.createRequest(METHOD.POST, body))
      .then(response => {
        this.validateStatus(response)
        return this.toJson(response)
      })
  }

  public put<T> (url: string, body?: unknown): Promise<T> {
    console.log('executing fetch with method %s on url %s', METHOD.PUT, url)
    return fetch(url, this.createRequest(METHOD.PUT, body))
      .then(response => {
        this.validateStatus(response)
        return this.toJson(response)
      })
  }

  private toJson<T> (response: Response): Promise<T> {
    return response.json()
      .then(body => {
        return body
      })
      .catch(reason => new Error('Can not parse body to json. Reason:' + reason + ', response body: ' + response.body))
  }

  private validateStatus (response: Response) {
    if (response.status >= 400) {
      console.error('error response received for fetch on url %s with status %s and body %s',
        response.url, response.status, JSON.stringify(response.body))
      throw new Error('fetch returned status: ' + response.status)
    } else {
      console.log('positive response received for fetch on url %s with status %s',
        response.url, response.status)
    }
  }

  private createRequest (fetchType: METHOD, body?: unknown): RequestInit {
    const request: RequestInit = {}
    request.method = fetchType
    if (body) {
      request.headers = {}
      request.headers.Accept = 'application/json'
      request.headers['Content-Type'] = 'application/json'
      request.body = JSON.stringify(body)
    }
    return request
  }
}

export default new Fetcher()

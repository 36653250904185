import AppSettings from '@/application/model/AppSettings'
import { BaseModule } from '@/application/store/BaseModule'

export const GETTER_APP_SETTINGS = 'getAppSettings'
export const MUTATION_APP_SETTINGS = 'user'
export const MUTATION_USER = 'setAppSettings'
export const MUTATION_TOKEN = 'setToken'
export const MUTATION_RESET_ALL = 'resetAll'

class Model {
  appSettings: AppSettings | null = null

  token: string | null = null

  user: string | null = null

  isOffline: boolean | null = null
}

class UserContextModule extends BaseModule<Model> {
  getters = {
    [GETTER_APP_SETTINGS]: (state: Model) => state.appSettings,
    getToken: (state: Model) => state.token,
    getUser: (state: Model) => state.user,
    getIsOffline: (state: Model) => state.isOffline
  }

  mutations = {
    [MUTATION_APP_SETTINGS]: (state: Model, newAppSettings: AppSettings) => {
      console.log('Mutating app setting to ' + JSON.stringify(newAppSettings))
      state.appSettings = newAppSettings
    },
    [MUTATION_USER]: (state: Model, user: string) => {
      console.log('Mutating user to ' + user)
      state.user = user
    },
    [MUTATION_TOKEN]: (state: Model, token: string) => {
      console.log('Mutating token to ' + token)
      state.token = token
    },
    [MUTATION_RESET_ALL]: (state: Model) => {
      console.log('Mutating user context to reset all values')
      state.appSettings = null
      state.token = null
      state.user = null
      state.isOffline = null
    }
  }
}

export default new UserContextModule(new Model())

import { Endpoints } from '@/application/backend/Endpoints'
import { VitalSignType } from '@/application/model/VitalSignType'

export const VitalSignsApi = {
  getPatientActiveVitals: (patientId: string, vitalSignType: VitalSignType, pollRateMillis: number, mock: boolean): EventSource => {
    console.log('Requesting %s for patient with id %s at poll rate %s and mock = %s', vitalSignType, patientId, pollRateMillis, mock)
    return new EventSource(Endpoints.patientActiveVitals(patientId, vitalSignType, pollRateMillis, mock))
  },

  getVitalsSourceVitals: (vitalsSourceId: string, vitalSignType: VitalSignType, pollRateMillis: number, mock: boolean): EventSource => {
    console.log('Requesting %s for vital source with id %s at poll rate %s and mock = %', vitalSignType, vitalsSourceId, pollRateMillis, mock)
    return new EventSource(Endpoints.vitalsSourceVitals(vitalsSourceId, vitalSignType, pollRateMillis, mock))
  }
}

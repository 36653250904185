















import { Component } from 'vue-property-decorator'
import BaseModel from '../../application/model/BaseModel'
import {
  ROUTE_ADMIN,
  ROUTE_HOSPITAL,
  ROUTE_PATIENTS,
  ROUTE_USER_SETTINGS,
  ROUTE_VITALS_SOURCES
} from '@/application/router/router'

interface NavIcon {
  label: string

  icon: string

  path: string
}

@Component({})
export default class FooterView extends BaseModel {
  constructor () {
    super('footer')
  }

  get navIcons (): NavIcon[] {
    return [
      { label: 'hospital', icon: 'mdi-hospital-building ', path: ROUTE_HOSPITAL },
      { label: 'vitals-sources', icon: 'mdi-watch-vibrate', path: ROUTE_VITALS_SOURCES },
      { label: 'patient-monitor', icon: 'mdi-heart-pulse', path: ROUTE_PATIENTS },
      { label: 'settings', icon: 'mdi-cog', path: ROUTE_USER_SETTINGS },
      { label: 'admin', icon: 'mdi-account-group', path: ROUTE_ADMIN }
    ]
  }
}

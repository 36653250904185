import { ActionContext } from 'vuex'
import { RootState } from '@/application/store/RootState'
import { VitalsThreshold } from '@/application/model/Thresholds'
import { OrganizazionalApi } from '@/application/backend/OrganizationalApi'
import { BaseModule } from '@/application/store/BaseModule'
import Hospital from '@/application/model/Hospital'
import { buildDeepQualifiedStoreMethodPath, NAMESPACE_SELECTED_HOSPITAL } from '@/application/store/RootStore'
import { GETTER_SELECTED_HOSPITAL } from '@/application/store/modules/selected/hospital/SelectedHospitalModule'

export const GETTER_ALL_VITALS_THRESHOLDS = 'getAll'
export const MUTATION_ALL_VITALS_THRESHOLDS = 'mutateAll'
export const ACTION_FETCH_ALL_VITALS_THRESHOLDS = 'fetchAll'
export const ACTION_PUT_ALL_VITALS_THRESHOLDS = 'put'

class Model {
  all = new Array<VitalsThreshold>()
}

class SelectedPatientVitalsThresholdsModule extends BaseModule<Model> {
  getters = {
    [GETTER_ALL_VITALS_THRESHOLDS]: (state: Model) => {
      console.log('getting the selected hospital thresholds ' + JSON.stringify(state.all))
      return state.all
    }
  }

  mutations = {
    [MUTATION_ALL_VITALS_THRESHOLDS]: (state: Model, newVitalsThresholds: Array<VitalsThreshold>) => {
      console.log('mutating selected hospital vital thresholds to ' + JSON.stringify(newVitalsThresholds))
      state.all = newVitalsThresholds
    }
  }

  actions = {
    [ACTION_FETCH_ALL_VITALS_THRESHOLDS]: async function (context: ActionContext<Model, RootState>): Promise<Array<VitalsThreshold>> {
      const selectedHospital: Hospital = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL), GETTER_SELECTED_HOSPITAL)]
      if (!selectedHospital) {
        context.commit(MUTATION_ALL_VITALS_THRESHOLDS, [])
        return new Promise(() => [])
      }
      return OrganizazionalApi.getHospitalVitalsThresholds(selectedHospital.id!)
        .then(result => {
          context.commit(MUTATION_ALL_VITALS_THRESHOLDS, result)
          return result
        })
    },
    [ACTION_PUT_ALL_VITALS_THRESHOLDS]: async function (context: ActionContext<Model, RootState>) {
      const selectedHospital: Hospital = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL), GETTER_SELECTED_HOSPITAL)]
      return OrganizazionalApi.putHospitalVitalsThresholds(selectedHospital.id!, context.state.all)
        .then(result => {
          context.dispatch(ACTION_FETCH_ALL_VITALS_THRESHOLDS)
          return result
        })
    }
  }
}

export default new SelectedPatientVitalsThresholdsModule(new Model())

import Hospital from '@/application/model/Hospital'
import { ActionContext } from 'vuex'
import { RootState } from '@/application/store/RootState'
import { OrganizazionalApi } from '@/application/backend/OrganizationalApi'
import { BaseModule } from '@/application/store/BaseModule'

export const STATE_HOSPITALS = 'all'
export const GETTER_HOSPITALS = 'getAll'
export const MUTATION_SET_HOSPITALS = 'setAll'
export const MUTATION_ADD_HOSPITAL = 'add'
export const MUTATION_UPDATE_HOSPITAL = 'update'
export const ACTION_FETCH_HOSPITALS = 'fetchAll'
export const ACTION_POST_HOSPITAL = 'post'
export const ACTION_PUT_HOSPITAL = 'put'

class Model {
  [STATE_HOSPITALS] = new Array<Hospital>()
}

class HospitalsModule extends BaseModule<Model> {
  getters = {
    [GETTER_HOSPITALS]: (state: Model) => {
      console.log('getting all hospitals from state')
      return state.all
    }
  }

  mutations = {
    [MUTATION_SET_HOSPITALS]: (state: Model, newHospitals: Array<Hospital>) => {
      console.log('mutating all hospitals state')
      state.all = newHospitals
    },
    [MUTATION_ADD_HOSPITAL]: (state: Model, newHospital: Hospital) => {
      console.log('mutating all hospitals state by adding an element')
      state.all.push(newHospital)
    },
    [MUTATION_UPDATE_HOSPITAL]: (state: Model, newHospital: Hospital) => {
      console.log('mutating all hospitals state by updating an element')
      const newHospitals: Array<Hospital> = state.all.filter(hospital => hospital.id !== newHospital.id)
      newHospitals.push(newHospital)
      state.all = newHospitals
    }
  }

  actions = {
    [ACTION_FETCH_HOSPITALS]: async function (context: ActionContext<Model, RootState>) {
      return OrganizazionalApi.getHospitals()
        .then(result => {
          context.commit(MUTATION_SET_HOSPITALS, result)
          return result
        })
    },
    [ACTION_POST_HOSPITAL]: async function (context: ActionContext<Model, RootState>, hospital: Hospital) {
      return OrganizazionalApi.postHospital(hospital)
        .then(result => {
          context.commit(MUTATION_ADD_HOSPITAL, result)
          return result
        })
    },
    [ACTION_PUT_HOSPITAL]: async function (context: ActionContext<Model, RootState>, hospital: Hospital) {
      return OrganizazionalApi.putHospital(hospital)
        .then(result => {
          context.dispatch(ACTION_FETCH_HOSPITALS)
          return result
        })
    }
  }
}

export default new HospitalsModule(new Model())

import { VitalSignType } from '@/application/model/VitalSignType'
import moment from 'moment'
import { PulseRate } from '@/application/model/PulseRate'
import { BodyTemperature } from '@/application/model/BodyTemperature'
import { OxygenSaturation } from '@/application/model/OxygenSaturation'
import VitalSignRaw from '@/application/model/VitalSignRaw'

export default abstract class VitalSign {
  type!: VitalSignType

  value!: number

  timestamp!: moment.Moment

  public static fromRaw (vitalSignRaw: VitalSignRaw) {
    const time = moment(vitalSignRaw.timestamp)
    switch (vitalSignRaw.type) {
      case VitalSignType.PULSE_RATE:
      {
        return new PulseRate(vitalSignRaw.value, time)
      }
      case VitalSignType.BODY_TEMPERATURE:
      {
        return new BodyTemperature(vitalSignRaw.value, time)
      }
      case VitalSignType.OXYGEN_SATURATION:
      {
        return new OxygenSaturation(vitalSignRaw.value, time)
      }
    }
  }

  public static convertVitalSignValueForCharts (vitalSign: VitalSign): number {
    if (vitalSign.type === VitalSignType.OXYGEN_SATURATION) {
      const result = vitalSign.value * 100
      return Number(result.toFixed(2)) // to percentage
    } else {
      return vitalSign.value
    }
  }
}

import { ActionContext } from 'vuex'
import Patient from '@/application/model/Patient'
import Hospital from '@/application/model/Hospital'
import PatientVitalsSource from '@/application/model/PatientVitalsSource'
import { RootState } from '@/application/store/RootState'
import { OrganizazionalApi } from '@/application/backend/OrganizationalApi'
import HospitalPatient from '@/application/model/HospitalPatient'
import { BaseModule } from '@/application/store/BaseModule'
import { buildDeepQualifiedStoreMethodPath, NAMESPACE_SELECTED_HOSPITAL } from '@/application/store/RootStore'
import { GETTER_SELECTED_HOSPITAL } from '@/application/store/modules/selected/hospital/SelectedHospitalModule'

export const GETTER_ADMITTED_PATIENTS = 'getAdmitted'
export const GETTER_SEARCHED_PATIENTS = 'getSearched'
export const MUTATION_ADMITTED_PATIENTS = 'setAdmitted'
export const MUTATION_ADD_ADMITTED_PATIENT = 'addAdmitted'
export const MUTATION_REMOVE_ADMITTED_PATIENT = 'removeAdmitted'
export const MUTATION_SEARCHED_PATIENTS = 'setSearched'
export const ACTION_FETCH_ADMITTED_PATIENTS = 'fetchAdmitted'
export const ACTION_SEARCH_PATIENTS = 'search'
export const ACTION_POST_PATIENT = 'post'
export const ACTION_ADMIT_PATIENT = 'admit'
export const ACTION_DISCHARGE_PATIENT = 'discharge'

class Model {
  admitted: Array<HospitalPatient> = new Array<HospitalPatient>()

  searched: Array<PatientVitalsSource> = new Array<PatientVitalsSource>()
}

class SelectedHospitalPatientsModule extends BaseModule<Model> {
  getters = {
    [GETTER_ADMITTED_PATIENTS]: (state: Model) => {
      console.log('getting admitted patients')
      return state.admitted
    },
    [GETTER_SEARCHED_PATIENTS]: (state: Model) => {
      console.log('getting admitted patients')
      return state.searched
    }
  }

  mutations = {
    [MUTATION_ADMITTED_PATIENTS]: (state: Model, newPatients: Array<HospitalPatient>) => {
      console.log('mutating admitted patients')
      state.admitted = newPatients
    },
    [MUTATION_ADD_ADMITTED_PATIENT]: (state: Model, admittedPatient: HospitalPatient) => {
      console.log('mutating admitted patients by adding ' + JSON.stringify(admittedPatient))
      state.admitted.push(admittedPatient)
    },
    [MUTATION_REMOVE_ADMITTED_PATIENT]: (state: Model, dischargedPatientId: string) => {
      console.log('mutating admitted patients by discharging ' + dischargedPatientId)
      state.admitted = state.admitted
        .filter(hospitalPatient => hospitalPatient.patient!.id !== dischargedPatientId)
    },
    [MUTATION_SEARCHED_PATIENTS]: (state: Model, newPatients: Array<PatientVitalsSource>) => {
      console.log('mutating searched patients')
      state.searched = newPatients
    }
  }

  actions = {
    [ACTION_FETCH_ADMITTED_PATIENTS]: async function (context: ActionContext<Model, RootState>): Promise<Array<HospitalPatient>> {
      const selectedHospital: Hospital | null = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL), GETTER_SELECTED_HOSPITAL)]
      if (!selectedHospital) {
        context.commit(MUTATION_ADMITTED_PATIENTS, [])
        return new Promise(() => [])
      }
      return OrganizazionalApi.getAdmittedPatients(selectedHospital.id!)
        .then(result => {
          context.commit(MUTATION_ADMITTED_PATIENTS, result)
          return result
        })
    },
    [ACTION_SEARCH_PATIENTS]: async function (context: ActionContext<Model, RootState>, patient: Patient): Promise<Array<PatientVitalsSource>> {
      const selectedHospital: Hospital = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL), GETTER_SELECTED_HOSPITAL)]
      return OrganizazionalApi.searchPatients(selectedHospital.patientDataProvider!.id!, patient)
        .then(result => {
          context.commit(MUTATION_SEARCHED_PATIENTS, result)
          return result
        })
    },
    [ACTION_POST_PATIENT]: async function (context: ActionContext<Model, RootState>, patient: Patient): Promise<Patient> {
      const selectedHospital: Hospital = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL), GETTER_SELECTED_HOSPITAL)]
      if (!selectedHospital) {
        throw new Error('Can not post patient. No hospital is selected.')
      }
      return OrganizazionalApi.postPatient(selectedHospital.patientDataProvider!.id!, patient.dataProviderPatientId!, patient)
    },
    [ACTION_ADMIT_PATIENT]: async function (context: ActionContext<Model, RootState>, patientId: string) {
      const selectedHospital: Hospital = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL), GETTER_SELECTED_HOSPITAL)]
      return OrganizazionalApi.admitPatient(selectedHospital.id!, patientId)
        .then(result => {
          context.commit(MUTATION_ADD_ADMITTED_PATIENT, result)
          return result
        })
    },
    [ACTION_DISCHARGE_PATIENT]: async function (context: ActionContext<Model, RootState>, patientId: string) {
      const selectedHospital: Hospital = context.rootGetters[buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL), GETTER_SELECTED_HOSPITAL)]
      return OrganizazionalApi.dischargePatient(selectedHospital.id!, patientId)
        .then(result => {
          context.commit(MUTATION_REMOVE_ADMITTED_PATIENT, patientId)
          return result
        })
    }
  }
}

export default new SelectedHospitalPatientsModule(new Model())














import { Component } from 'vue-property-decorator'
import FooterView from '@/components/common/FooterView.vue'
import HeaderView from '@/components/common/HeaderView.vue'
import BaseModel from './application/model/BaseModel'
import { Getter } from 'vuex-class'
import Hospital from '@/application/model/Hospital'
import { buildQualifiedStoreMethodPath, NAMESPACE_SELECTED_HOSPITAL } from '@/application/store/RootStore'
import { GETTER_SELECTED_HOSPITAL } from '@/application/store/modules/selected/hospital/SelectedHospitalModule'

@Component({
  components: {
    Header: HeaderView,
    Footer: FooterView
  }
})

export default class App extends BaseModel {
  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_HOSPITAL, GETTER_SELECTED_HOSPITAL))
  selectedHospital!: Hospital

  constructor () {
    super('app')
  }
}

import { Module } from 'vuex'
import { RootState } from '@/application/store/RootState'

export class BaseModule<Model> implements Module<Model, RootState> {
  namespaced = true

  state!: Model

  constructor (model: Model) {
    this.state = model
  }
}

import { VitalSignType } from '@/application/model/VitalSignType'
import VitalSign from '@/application/model/VitalSign'

export const BODY_TEMPERATURE_MIN = 32

export const BODY_TEMPERATURE_MAX = 42

export const BODY_TEMPERATURE_TICK = 10

export class BodyTemperature implements VitalSign {
  type = VitalSignType.BODY_TEMPERATURE

  value: number

  timestamp: moment.Moment

  constructor (value: number, timestamp: moment.Moment) {
    this.value = value
    this.timestamp = timestamp
  }
}

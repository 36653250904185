

































import { Component } from 'vue-property-decorator'
import BaseView from '../../application/model/BaseModel'
import SelectHospitalView from '@/components/common/SelectHospitalView.vue'
import { Getter } from 'vuex-class'
import Hospital from '@/application/model/Hospital'
import { buildQualifiedStoreMethodPath, NAMESPACE_SELECTED_HOSPITAL } from '@/application/store/RootStore'
import { GETTER_SELECTED_HOSPITAL } from '@/application/store/modules/selected/hospital/SelectedHospitalModule'

@Component({
  components: { SelectHospital: SelectHospitalView }
})
export default class HeaderView extends BaseView {
  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_HOSPITAL, GETTER_SELECTED_HOSPITAL))
  selectedHospital!: Hospital

  constructor () {
    super('header')
  }

  get hospitalName (): string {
    if (!this.selectedHospital) {
      return this.getLangText('choose-hospital')
    } else {
      return this.selectedHospital!.name!
    }
  }
}

